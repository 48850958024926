<template>
  <div>
    <h2 class="section-header">
      <img src="@/assets/images/recruit-black.svg" alt="recruit" />
      Recruit Smart Asscociates
    </h2>

    <div class="form">
      <h3>Recruit Smart Asscociates</h3>
      <div class="fields">
        <div class="primary-fields">
          <div class="line-group">
            <div class="input-group">
              <input type="text" placeholder="First Name" v-model="firstName" />
              <p class="error" v-if="firstNameError">{{ firstNameError }}</p>
            </div>

            <div class="input-group">
              <input type="text" placeholder="Last Name" v-model="lastName" />
              <p class="error" v-if="lastNameError">{{ lastNameError }}</p>
            </div>
          </div>

          <div class="line-group">
            <div class="input-group">
              <input type="text" placeholder="Phone" v-model="phoneNumber" />
              <p class="error" v-if="phoneNumberError">
                {{ phoneNumberError }}
              </p>
            </div>

            <div class="input-group">
              <input type="email" placeholder="Email Address" v-model="email" />
              <p class="error" v-if="emailError">{{ emailError }}</p>
            </div>
          </div>
        </div>

        <button class="update" @click="recruit()" :disabled="processing">
          <img src="@/assets/images/add-user.svg" alt="recruit user" />
          {{ processing ? "Recruiting..." : "Recruit" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import recruitRealtor from "../services/recruitRealtor";
import Validators from "../helpers/Validators";
export default {
  name: "Account",
  data() {
    return {
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      phoneNumber: "",
      phoneNumberError: "",
      email: "",
      emailError: "",
      processing: false
    };
  },
  created() {
    this.subscription = this.$store.getters.getSubscription;
  },
  methods: {
    recruit() {
      if (this.subscription != "YES") {
        return this.$toast.error("Subscribe to recruit");
      }
      const forFirstName = new Validators();
      forFirstName.required(this.firstName);
      forFirstName.onlyLetters(this.firstName);
      this.firstNameError = forFirstName.errors;

      const forLastName = new Validators();
      forLastName.required(this.lastName);
      forLastName.onlyLetters(this.lastname);
      this.lastNameError = forLastName.errors;

      const forEmail = new Validators();
      forEmail.required(this.email);
      forEmail.validEmail(this.email);
      this.emailError = forEmail.errors;

      const forPhoneNumber = new Validators();
      forPhoneNumber.onlyNumbers(this.phoneNumber);
      this.phoneNumberError = forPhoneNumber.errors;

      if (
        this.firstNameError.length === 0 &&
        this.lastNameError.length === 0 &&
        this.phoneNumberError.length === 0
      ) {
        // TODO: Ensure that there is a change in point when you add a new realtor.
        // just check to ensure that the backend is connected well to the frontend.
        let body = {
          email: this.email,
          firstname: this.firstName,
          lastname: this.lastName,
          phone: this.phoneNumber
        };

        body = JSON.stringify(body);

        this.processing = true;
        recruitRealtor
          .add(body)
          .then(data => {
            if (data.success) {
              this.$toast.success("You have successfully added a realtor");
              this.reset();
            } else {
              this.$toast.error(data.error);
            }
          })
          .catch(() => {
            this.profilePicStatus = "";
            this.$toast.error("please check your network and refresh the page");
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
    reset() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phoneNumber = "";
    }
  }
};
</script>

<style scoped>
@import "../styles/section-header.css";
@import "../styles/forms.css";
</style>
